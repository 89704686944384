<!-- <div class="main-banner-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h2>Enjoy expertly crafted, authentic dishes prepared by our artisanal local chefs</h2>
                            <p>Welcome to CHEFMI, your go-to platform for online food ordering and delivery. Whether you're craving home-cooked meals, restaurant favorites, or catering services, our diverse community of home chefs, restaurant chefs, and catering chefs has got you covered. Enjoy nutritious and affordable dishes, available for immediate delivery or scheduled orders. Savor the taste of health with CHEFMI.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/home-two/1.png" class="wow fadeInUp" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/2.png" class="wow fadeInLeft" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/3.png" class="wow fadeInRight" data-wow-delay="1s"
                                alt="image">
                            <img src="assets/img/home-two/4.png" class="wow fadeInUp" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/5.png" class="wow zoomIn" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/main-image.png" class="wow zoomIn" data-wow-delay="1s"
                                alt="image">
                        </div>
                        <div class="circle-img">
                            <img src="assets/img/home-two/shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-dot"><img src="assets/img/main-banner-shape/dot.png" alt="image"></div>
</div> -->

<div class="main-banner-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid h-100 p-0">
                <div class="row align-items-center h-100">
                    <div class="col-lg-6 col-md-12 banner-bg-img">
                        <!-- <div class="banner-image">
                            <img src="assets/img/home-three/1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/2.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/3.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/4.png" class="wow zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/5.png" class="wow bounceIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/6.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/7.png" class="wow zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/8.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/9.png" class="wow rotateIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/10.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/11.png" class="wow zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/main-image.png" class="wow zoomIn" data-wow-delay="0.12s" alt="image">
                        </div> -->
                    </div>
                    <div class="col-lg-6 col-md-12 h-100 d-flex align-items-center justify-content-center">
                        <div class="main-banner-content text-center">
                            <h2>Enjoy Expertly Crafted, Authentic Dishes Prepared by our Artisanal Local Chefs</h2>
                            <!-- <p>Welcome to CHEFMI, your go-to platform for online food ordering and delivery. Whether you're craving home-cooked meals, restaurant favorites, or catering services, our diverse community of home chefs, restaurant chefs, and catering chefs has got you covered. Enjoy nutritious and affordable dishes, available for immediate delivery or scheduled orders. Savor the taste of health with CHEFMI.</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="shape-img1"><img src="assets/img/main-banner-shape/2.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/main-banner-shape/3.png" alt="image"></div> -->
</div>

<section class="process-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h3>Eat What You Love</h3>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/nutritious-food.png" alt="image" style="width: 200px; border-radius: 60px;">
                    </div>
                    <div class="process-content">
                        <h3>Authentic Home Cooked Meals</h3>
                        <p>Enjoy delicious, authentic, handcrafted meals made daily with fresh, quality ingredients. Prepared fresh, chilled, and delivered to your doorstep, starting at just $9 per meal.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/food-dlivery.png" alt="image" style="width: 200px; border-radius: 60px;">
                    </div>
                    <div class="process-content">
                        <h3>Best In Class Restaurant Food</h3>
                        <p>Experience highly curated, top-reviewed local restaurant cuisines, often unavailable on traditional food delivery platforms due to distance, now available with 2-3 hour scheduled delivery at an affordable cost.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/chef-mgmt.png" alt="image" style="width: 200px; border-radius: 60px;">
                    </div>
                    <div class="process-content">
                        <h3>Local Catering Expert</h3>
                        <p>Local catering experts for any event, offering customized menus, exceptional service, and impressive culinary experiences. From intimate gatherings to grand celebrations, our team meticulously handles every detail to ensure a flawless occasion.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section main-clr-bg ptb-100 howto-order">
    <div class="container">
        <div class="section-title">
            <h3>Order Your Meal in Just Three Easy Steps</h3>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-12">
                <div class="d-flex justify-content-center">
                    <div class="d-lg-flex align-items-center text-center stp-blk">
                        <img src="assets/img/how-to-order-st1.png" class="me-lg-2" alt="image">
                        <div class="font-24 font-white text-lg-start">Select your delivery date</div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-12">
                <div class="d-flex justify-content-center">
                    <div class="d-lg-flex align-items-center text-center stp-blk">
                        <img src="assets/img/how-to-order-st2.png" class="me-lg-2" alt="image">
                        <div class="font-24 font-white text-lg-start">Select your item and customize it</div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-12">
                <div class="d-flex justify-content-center">
                    <div class="d-lg-flex align-items-center text-center stp-blk">
                        <img src="assets/img/how-to-order-st3.png" class="me-lg-2" alt="image">
                        <div class="font-24 font-white text-lg-start">Enjoy it!</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="process-section ptb-100 tert-Yelwclr-bg">
    <div class="container">
        <div class="section-title">
            <h3>Share Your Passion for Cooking with CHEFMI</h3>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/home-chef.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Home Chef</h3>
                        <p>Become a home chef and share your love for cooking with your local community</p>
                        <div class="process-btn">
                            <a class="default-btn" routerLink="/contact">Join Now<span></span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/restaurant-chef.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Restaurant Chef</h3>
                        <p>Are you a local restaurant chef? Join us and showcase your masterpieces to a community of food enthusiasts.</p>
                        <div class="process-btn">
                            <a class="default-btn" routerLink="/contact">Join Now<span></span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/catering-chef.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Catering Chef</h3>
                        <p>Become a caterer and transform ordinary gatherings into unforgettable memories.</p>
                        <div class="process-btn">
                            <a class="default-btn" routerLink="/contact">Join Now<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="about-image">
                    <img src="assets/img/screens.png" alt="image">
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="about-content">
                    <h3>Seamless Multi-Device Access</h3>
                    <p>Stay connected and manage your orders effortlessly from any device. CHEFMI's platform is designed for easy access on smartphones, tablets, and computers, ensuring you have control and convenience at your fingertips, anytime and anywhere.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>