<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h3>Focused on Actionable Insights</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <ul class="about-list">
                        <li><i class="flaticon-tick"></i> Professional Consultancy Service</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support Center</li>
                        <li><i class="flaticon-tick"></i> Custom Service & Operation</li>
                        <li><i class="flaticon-tick"></i> Advanced Advisory Team</li>
                        <li><i class="flaticon-tick"></i> Professional Consultancy Service</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support Center</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="820">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Management</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="20">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Managed  Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="120">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Big Data</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="productive-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="productive-content">
                    <span>Let’s Get Started</span>
                    <h3>Are you ready for a better, more productive business </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices Lorem Ipsum is simply dummy text.</p>
                    <div class="productive-btn">
                        <a routerLink="/projects" class="productive-btn">Get Started Project <span></span></a>
                        <a routerLink="/contact" class="productive-btn-one">Contact With Us <span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="productive-image">
                    <img src="assets/img/productive.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-section ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h3>What Our Client Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Richard Denial</h3>
                    <span>IT Executive</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/2.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Sarah Taylor</h3>
                    <span>Lead Architecure</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Alastair Cook</h3>
                    <span>Marketing Manager</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-shape"><img src="assets/img/shape/testimonial-shape-1.png" alt="main-image"></div>
    <div class="testimonial-shape-img1"><img src="assets/img/shape/testimonial-shape-2.png" alt="image"></div>
    <div class="testimonial-shape-img2"><img src="assets/img/shape/testimonial-shape-3.png" alt="image"></div>
</section>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Experts</span>
            <h3>Meet Our Leadership Preparing For Your Success</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-item">
                    <div class="team-image">
                        <img src="assets/img/team/1.jpg" alt="team">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="team-content">
                        <h3>Thomas Edison</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-item">
                    <div class="team-image">
                        <img src="assets/img/team/2.jpg" alt="team">
                        
                        <div class="team-social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="team-content">
                        <h3>Louis Pasteur</h3>
                        <span>Project Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3">
                <div class="single-team-item">
                    <div class="team-image">
                        <img src="assets/img/team/3.jpg" alt="team">
                        
                        <div class="team-social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="team-content">
                        <h3>Pierre Curie</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-section pt-100 pb-70">
    <div class="container">
        <div class="partner-title">
            <span>Customer</span>
            <h3>Discover Customer Successful Stories</h3>
        </div>
        <div class="partner-list">
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/2.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/3.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/4.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/5.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/6.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/7.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/8.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/shape/partnar-shape-1.png" alt="main-image"></div>
    <div class="partner-shape-img1"><img src="assets/img/shape/partnar-shape-2.png" alt="image"></div>
</section>

<section class="subscribe-section ptb-100">
    <div class="container">
        <div class="subscribe-content-area mb-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-image">
                        <img src="assets/img/subscribe.png" alt="image">     
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-content">
                        <h2>Don’t Miss Our News And Updates</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>