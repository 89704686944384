<div class="ptb-100 five-Yelwclr-bg">
    <div class="container">
        <div class="text-center mb-5">
            <img src="assets/img/logo.png" alt="image" style="height: 32px;" class="mb-3">
            <div class="fs-6">Savor homemade, healthy dishes crafted by local chefs for your family.</div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="single-footer-widget text-center">
                    <div class="footer-heading">
                        <h3>Learn</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="">About CHEFMI</a></li>
                        <li><a routerLink="">Food Safety</a></li>
                        <li><a routerLink="">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single-footer-widget text-center">
                    <div class="footer-heading">
                        <h3>Community</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li> <i class="fab fa-facebook-f me-2"></i><a>Facebook</a></li>
                        <li> <i class="fab fa-instagram me-2"></i><a>Instagram</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single-footer-widget text-center">
                    <div class="footer-heading">
                        <h3>Resources</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="">Sign Up</a></li>
                        <li><a routerLink="/contact">Become a Chef</a></li>
                        <li><a routerLink="">Help Center</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<div class="copyright-area five-Yelwclr-bg">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="d-flex justify-content-center align-items-center" style="font-size: 12px;"><i
                    class="far fa-copyright me-2"></i> 2024 CHEFMI. All Rights Reserved</div>
            <!-- <div class="col-12">
                <p><i class="far fa-copyright"></i>2024 CHEFMI. All Rights Reserved</p>
            </div> -->
            <!-- <div class="col-lg-6 col-md-6">
                <ul>
                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div> -->
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>