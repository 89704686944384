<div class="main-banner-three contact-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid h-100 p-0">
                <div class="row align-items-center h-100">
                    <div class="col-lg-6 col-md-12 h-100 d-flex align-items-center justify-content-center">
                        <div class="main-banner-content text-center">
                            <h2>Unlock New Culinary Opportunities</h2>
                            <h3 class="main-txt">
                                Turn your passion into profit.
                            </h3>
                            <p>We empower you with everything you need to start and manage your own food business, your way</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 contact-banner-bg-img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="shape-img1"><img src="assets/img/main-banner-shape/2.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/main-banner-shape/3.png" alt="image"></div> -->
</div>

<div class="contact-section ptb-100 px-5">
    <div class="container">
        <div class="section-title">
            <h3>Join Our Community of Chefs</h3>
            <div class="mt-3 p-3 info-clr">Please fill in your details below and <br>clearly mention in the subject field how you would like to join as a chef, <br>e.g., Home Chef, Restaurant Chef, or Catering Chef.
            </div>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name" required>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email" required>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message" required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 d-flex justify-content-center">
                                <button type="submit" class="default-btn">Submit Now</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>